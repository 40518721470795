// React imports
import React from 'react';

// UI – style imports
import { WithStyles, withStyles, useMediaQuery, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// local imports
import { styles } from './TermsAndConditions.style';

function TermsAndConditions(props: WithStyles<typeof styles>) {
    const { classes } = props;

    const bars = useMediaQuery('(min-width:1300)');
    const firstDots = useMediaQuery('(min-width:900px)');
    const mobileBreakpoint = useMediaQuery('(min-width:450px)');

    const blueBreak = useMediaQuery('(min-width:560px)');
    const mobileStart = useMediaQuery('(max-width:749px)');

    const theme = useTheme();
    const normalDots = useMediaQuery(theme.breakpoints.up(1151));
    const mediumDots = useMediaQuery(theme.breakpoints.between(900, 1151));
    const smallerDots = useMediaQuery(theme.breakpoints.between(750, 900));
    const inBetweenSize = useMediaQuery(theme.breakpoints.between(640, 750));
    const reallySmall = useMediaQuery(theme.breakpoints.between(510, 640));
    const smallest = useMediaQuery(theme.breakpoints.down(510));

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <Box className={classes.titleContainer}>
                    <p className={classes.title}>TÉRMINOS</p>
                    <p id="bot" className={classes.title}>
                        Y CONDICIONES
                    </p>
                </Box>
                <Box className={classes.section}>
                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>Última modificación: 1 de diciembre de 2023</p>
                    </Box>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Las actividades que realiza Cuenca Tecnología Financiera, S.A. de C.V. Institución de Fondos
                            de Pago Electrónico (“Cuenca”) están sujetas a la regulación establecida en la Ley para
                            Regular las Instituciones de Tecnología Financiera.
                        </p>
                    </Box>
                </Box>

                <Box className={classes.section}>
                    <Box className={classes.subtitleContainer}>
                        <p className={classes.number}>I.</p>
                        <p className={classes.subtitle}>
                            Obligatoriedad, consentimiento y aceptación de los términos y condiciones de uso y
                            restricciones de la plataforma.
                        </p>
                    </Box>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Estos términos y condiciones de uso (en adelante los "Términos y Condiciones") constituyen
                            las bases legales y manifestaciones que Cuenca establece con cualquier persona física o
                            moral (en adelante el "Cliente") a través de la Plataforma y su página de internet con
                            relación a las operaciones, servicios o actividades de éstas.
                        </p>
                        <p className={classes.text}>
                            Estos Términos y Condiciones describen la regulación de la descarga, navegación y de las
                            aplicaciones disponibles para IOS y Android (en adelante la "Plataforma" y/o "Aplicación"),
                            disponible en AppStore, Playstore y Appgallery, así como los derechos y obligaciones del
                            Cliente y de Cuenca (conjuntamente, las "Partes") al utilizar el servicio de gestión o
                            procesamiento de pagos, mediante la Plataforma o demás herramientas que Cuenca provea.
                            (conjuntamente los "Servicios").
                        </p>
                        <p className={classes.text}>
                            El Cliente debe leer, entender y aceptar de forma expresa todas las condiciones establecidas
                            en estos Términos y Condiciones y demás políticas y principios incorporados a los mismos por
                            referencia, una vez completados los pasos establecidos en el inciso II, numeral 1, previo a
                            su registro como Cliente y/o la utilización de los Servicios, mediante el proceso
                            establecido en la aplicación.
                        </p>
                        <p className={classes.text}>
                            El Cliente, en este acto, acepta expresamente y manifiesta su consentimiento a los Términos
                            y Condiciones a través de su firma autógrafa digitalizada que hace constar mediante el
                            registro y aceptación en la Plataforma.
                        </p>
                        <p className={classes.text}>
                            Además, el Cliente acepta que Cuenca podrá hacer valer vía judicial sus derechos y
                            reclamaciones, siendo admisibles como prueba estos Términos y Condiciones, así como toda la
                            información que conste en las bases de datos de Cuenca para evidenciar la relación con el
                            Cliente y los adeudos que se tengan, en caso de que aplique.
                        </p>
                    </Box>
                    <p className={classes.clause}>
                        1. DECLARACIONES DEL USUARIO. USOS PROHIBIDOS Y RESTRICCIONES DE LA PLATAFORMA.
                    </p>
                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            El Cliente manifiesta que el objeto por el cual hace disposiciones de fondos de pago
                            electrónico no infringe ninguna ley aplicable, ni es por un servicio, venta o transmisión
                            que esté prohibida por la ley o los presentes Términos y Condiciones, tales como y sin
                            limitarse a: (i) juegos de azar, apuestas, etc.; (ii) tráfico de armas, de personas, de
                            animales, etc.; (iii) lavado de dinero y/o financiamiento al terrorismo; (iv) pornografía,
                            prostitución o pedofilia; y/o (vi) cualquier tipo de actividad que pueda ser considerada
                            fraudulenta, ilegal o sospechosa de serlo.
                        </p>
                        <p className={classes.text}>
                            El Cliente acepta en todo momento apegarse a las políticas y procedimientos establecidos en
                            materia de Prevención de Lavado de Dinero y Financiamiento al Terrorismo así como a
                            coadyuvar con Cuenca en todo momento para dar cumplimiento a las obligaciones aplicables,
                            por lo cual, mientras persista la relación contractual, acepta proporcionar la información
                            y/o documentación que le sea requerida por Cuenca con el objeto de atender cabalmente tanto
                            a lo establecido en la normatividad aplicable en dicha materia, así como a las políticas,
                            procedimientos y demás lineamientos que para tal efecto se encuentren vigentes, razón por la
                            cual, se tiene por consentido que en cualquier momento podrán existir requerimientos de
                            información y/o documentación adicionales a los previamente convenidos.
                        </p>
                        <p className={classes.text}>
                            En virtud de lo anterior, Cuenca se reserva el derecho de solicitarle al Cliente cualquier
                            información, dato y/o documento adicional con la intención de determinar, actualizar y/o
                            corroborar el perfil transaccional, así como el origen y destino de los fondos involucrados
                            en las operaciones que el Cliente realice en virtud de estos Términos y Condiciones.
                        </p>
                        <p className={classes.text}>
                            El Cliente acepta a su vez que, sin necesidad de declaración judicial previa y, previo a la
                            solicitud de información adicional y el análisis correspondiente, se podrá a total
                            discreción de Cuenca, restringir de forma temporal o definitiva, la Cuenta de Fondos de Pago
                            Electrónico y/o el acceso a la App, para lo cual Cuenca deberá darle aviso al Cliente.
                            Derivado de lo anterior, en el supuesto de que el Cliente se comunique con Cuenca a través
                            de la Plataforma de Comunicación validando su identidad en los términos establecidos en el
                            último párrafo de la Cláusula VIGÉSIMA OCTAVA del Contrato de Adhesión se llevará a cabo la
                            notificación de la resolución correspondiente.
                        </p>
                        <p className={classes.text}>
                            En caso de que Cuenca determine suspender o dar por terminada la relación contractual con
                            base en lo previsto en los párrafos precedentes y el Cliente mantenga saldo en su Cuenta de
                            Fondos de Pago Electrónico, el mencionado remanente podrá ser transferido a la cuenta de
                            depósito que se encuentre abierta en otra institución financiera autorizada para recibir
                            depósitos o transferencias en términos de la regulación aplicable autorizada y que el
                            Cliente haya señalado por escrito y de manera expresa, previo cumplimiento de los requisitos
                            que al efecto se tengan establecidos y de conformidad con la normatividad que les resulte
                            aplicable. Para la transferencia de dichos fondos a la cuenta anteriormente referida, el
                            Cliente deberá proporcionar a Cuenca un estado de cuenta en el que aparezca como titular de
                            la misma y la CLABE para la transferencia de los fondos.
                        </p>
                        <p className={classes.text}>
                            En todo caso, el Cliente responderá por los daños y perjuicios que pueda haber ocasionado a
                            Cuenca, sus funcionarios, empleados, directores, agentes, y/o empleados, a cuyos efectos
                            Cuenca se reserva el derecho de iniciar las acciones judiciales o extrajudiciales que estime
                            pertinentes.
                        </p>
                        <p className={classes.text}>
                            El Cliente será exclusiva e ilimitadamente responsable por los daños y perjuicios que su
                            conducta pueda causar a Cuenca o a los demás Clientes de los Servicios de Cuenca, así como
                            por cualquier otra responsabilidad de índole administrativa, penal, civil, laboral o
                            cualquier otra que su conducta o actividad pudiera causar.
                        </p>
                        <p className={classes.text}>
                            El Cliente reconoce que Cuenca le ha advertido que existen varias estafas que utilizan la
                            red para realizar transacciones ilícitas como smishing, phishing, pharming, etc. Cuenca
                            pondrá a disposición del Cliente de manera gratuita a través de su página de internet y de
                            sus redes sociales información para evitar posibles riesgos tecnológicos, cibernéticos y de
                            fraude en los productos y servicios financieros contratados. Asimismo, enviará
                            notificaciones de operaciones inusuales para que el Cliente mediante la Plataforma las
                            autorice. En el mismo sentido, las operaciones efectuadas mediante transferencias
                            electrónicas, cuando sean inusuales, serán objeto de revisión por parte del área encargada
                            de la prevención de operaciones fraudulentas.
                        </p>
                        <p className={classes.text}>
                            Cuenca podrá solicitar información adicional para verificar la licitud de las transacciones
                            realizadas mediante la utilización de los Servicios de Cuenca.
                        </p>
                    </Box>
                </Box>

                <Box className={classes.section}>
                    <Box className={classes.subtitleContainer}>
                        <p className={classes.number}>II.</p>
                        <p className={classes.subtitle}>Cuentas</p>
                    </Box>

                    <p className={classes.clause}>1. REGISTRO Y DOMICILIACIÓN</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Todo Cliente que desee utilizar los Servicios deberá crear una cuenta (en adelante la
                            "Cuenta") dentro de la Plataforma. El Cliente deberá completar todos los campos del
                            formulario de registro con sus datos de identificación y contacto, así como cualquier otra
                            información que Cuenca requiera para poder garantizar la prestación de los Servicios. El
                            Cliente deberá adjuntar los documentos que acrediten su personalidad, comprometiéndose a
                            mantener la información exacta, completa y actualizada.
                        </p>
                        <p className={classes.text}>
                            El Cliente se obliga a presentar información verídica y documentación válida emitida por
                            autoridad competente. Actuando siempre en nombre propio.
                        </p>
                        <p className={classes.text}>
                            Cuenca se reserva el derecho de solicitar comprobantes y/o información adicional a efectos
                            de corroborar la información manifestada por el Cliente, así como de suspender temporal o
                            definitivamente a aquellos Clientes cuyos datos no hayan podido ser confirmados o validados,
                            sin previo aviso.
                        </p>
                        <p className={classes.text}>
                            Cuenca se reserva el derecho de rechazar una solicitud de registro o de cancelar o
                            suspender, temporal o definitivamente una Cuenta, sin previo aviso, en caso de detectar
                            incongruencias o inconsistencias en la información provista por un Cliente o en caso de
                            detectar actividades sospechosas, sin que tal decisión genere para el Cliente derechos de
                            indemnización o resarcimiento.
                        </p>
                    </Box>

                    <p className={classes.clause}>2. LA CUENTA</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Una vez completado el registro, la Cuenta será nivel 1, de conformidad con lo establecido en
                            el numeral 6 del presente capítulo.
                        </p>
                        <p className={classes.text}>
                            En el proceso de registro, dependiendo del nivel de Cuenta y de conformidad con la
                            legislación mexicana aplicable, Cuenca podrá pedir información adicional al Cliente,
                            incluyendo documentación, datos personales y/o cualquier otro tipo de información.
                        </p>
                        <p className={classes.text}>
                            Cada Cuenta esta reflejada en pesos mexicanos y todas las referencias a dinero en el sistema
                            se realizan en la moneda de curso legal en México, a excepción de que se indique
                            expresamente lo contrario.
                        </p>
                        <p className={classes.text}>
                            La Cuenta Cuenca contará con 15 transferencias electrónicas de fondos gratuitas a una
                            entidad financiera distinta a Cuenca en el transcurso de un mes calendario. Una vez superado
                            este límite, la cuenta será catalogada ese mes bajo la modalidad de "Cuenca SinLímites", por
                            lo que cada una de las transacciones realizadas a entidades distintas a Cuenca tendrán una
                            comisión de $8.62 Pesos M.N. + IVA = ($10 Pesos M.N.).
                        </p>
                    </Box>

                    <p className={classes.clause}>3. ACCESO</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Se aclara que en todos los casos la Cuenta, es personal, única e intransferible, y será
                            asignada a un solo Cliente y está prohibida su cesión, venta o transferencia bajo ningún
                            título. El Cliente no podrá permitir ni autorizar el uso de su Cuenta por terceras personas.
                        </p>
                    </Box>
                    <p className={classes.subclause}>
                        3.1. RESPONSABILIDAD DEL CLIENTE EN RELACION CON SUS CLAVES Y CONTRASEÑAS.
                    </p>
                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            El acceso a la Cuenta a través de la Plataforma se realizará mediante el ingreso de una
                            contraseña creada por el Cliente con anterioridad o mediante el ingreso de biométricos
                            (huella, reconocimiento facial, etc.).
                        </p>
                        <p className={classes.text}>
                            El uso y protección de la contraseña del Cliente será responsabilidad del mismo, la cual
                            deberá ser memorizada y guardarla bajo la más estricta confidencialidad, en el entendido que
                            no podrá compartirla con terceros y evitará mandar información confidencial por correo
                            electrónico, páginas de internet no seguras, redes sociales.
                        </p>
                        <p className={classes.text}>
                            Cuenca únicamente requerirá al Cliente que ingrese su contraseña para: i) ingresar a la
                            Plataforma; ii) Autorizar Operaciones; iii) Para cambiar información de la Cuenta. Por lo
                            que nunca solicitará al Cliente su contraseña o NIP, saldos o datos personales por ninguno
                            de los medios siguientes: teléfono, correo electrónico, páginas web no seguras o redes
                            sociales.
                        </p>
                        <p className={classes.text}>
                            En caso de el Cliente pierda, extravíe u olvide su contraseña para el acceso a la
                            Plataforma, deberá seguir el procedimiento que se señala en las secciones siguientes.
                        </p>
                    </Box>

                    <p className={classes.subclause}>3.1.1 PROCESO DE RESTABLECIMIENTO DE CONTRASEÑAS O CLAVES</p>

                    <p className={classes.text}>
                        En caso de que el Cliente necesite cambiar sus contraseñas o haya olvidado las mismas deberá
                        seguir el proceso siguiente:
                    </p>

                    <p className={classes.subclause}>I.- Por medio del Servicio de Atención al Cliente:</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            a) Contactar al Servicio de Atención al Cliente, solicitando iniciar el proceso de
                            restablecimiento de contraseña o clave, a través de uno o varios de los medios siguientes:
                            (i) A través de la Plataforma; o (ii) A través de la aplicación de mensajería instantánea
                            denominada "WhatsApp" por medio de la cuenta de empresa oficial verificada de cuenca,
                            registrada con el número telefónico del Cliente.
                        </p>
                        <p className={classes.text}>
                            b) Un miembro de Atención al Cliente le solicitará comprobar su identidad, mediante un video
                            en el cual el cliente deberá sostener su identificación y mencionar su nombre, la fecha y su
                            solicitud.
                        </p>
                        <p className={classes.text}>
                            c) El miembro autorizado de Atención al Cliente levantará un comprobante con un número de
                            referencia "ticket" e iniciará el procedimiento interno para restablecer la contraseña.
                        </p>
                        <p className={classes.text}>
                            d) Una vez que la contraseña se haya restablecido en los Sistemas Cuenca, el Cliente
                            recibirá una notificación informándole que deberá entrar a su Plataforma para poder crear
                            una nueva contraseña.
                        </p>
                        <p className={classes.text}>
                            e) Una vez creada la nueva contraseña, únicamente el Cliente la sabrá, y la anterior
                            contraseña dejará de ser válida.
                        </p>
                    </Box>

                    <p className={classes.subclause}>
                        II.- Mediante la Plataforma a través de "Personas de Confianza":
                    </p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Cuenca le ofrece a sus Clientes el servicio de "Personas de Confianza", el cual consiste en
                            elegir 3 personas dentro de tu circulo de confianza, las cuales te podrán apoyar a:
                        </p>
                        <p className={classes.text}>
                            - Poner tu cuenta/tarjeta emitida por Cuenca o mediante alianzas comerciales en modo seguro
                            en caso de robo o extravío.
                        </p>
                        <p className={classes.text}>- Restablecer contraseña a través de la generación de códigos.</p>
                        <p className={classes.text}>
                            Los contactos seleccionados pueden ser cambiados cuando y cuantas veces lo desee el cliente.
                        </p>
                    </Box>

                    <p className={classes.text}>
                        Cuando un Cliente olvide su contraseña, podrá restablecerla mediante el uso de sus personas de
                        confianza, realizando el siguiente proceso:
                    </p>

                    <p className={classes.text}>
                        1.- El Cliente afectado deberá contactar a dos de sus personas de confianza.
                    </p>

                    <p className={classes.text}>
                        2.- Las personas de confianza deberán ingresar a su propia Plataforma y dirigirse a las
                        secciones que se indican a continuación para poder generar un código: a).- Personas que confían
                        en ti; b).- Nombre de la persona afectada; c).- Restablecer contraseña.
                    </p>

                    <p className={classes.text}>
                        Para que el restablecimiento sea exitoso, es necesario que por lo menos dos de las "personas de
                        confianza" realicen el procedimiento antes descrito y se generen dos códigos diferentes.
                    </p>

                    <p className={classes.text}>
                        3.- Una vez que el Cliente cuente con dos códigos, podrá restablecer su contraseña, desde su
                        aplicación en la sección "Olvidé mi contraseña".
                    </p>

                    <p className={classes.text}>
                        Los códigos proporcionados por las personas de confianza únicamente servirán para que Cuenca
                        realice el proceso para el restablecimiento de la contraseña, las personas de confianza no
                        podrán conocer ni modificar bajo ninguna circunstancia la información de la Cuenta del Cliente.
                    </p>

                    <p className={classes.subclause}>
                        3.1.2. Poner tu cuenta/tarjeta en modo seguro en caso de robo o extravío.
                    </p>

                    <p className={classes.text}>
                        El Cliente afectado deberá contactar a una de sus personas de confianza, para que este ultimo,
                        ingresando a su propia aplicación pueda poner en modo seguridad la Cuenta del cliente afectado,
                        lo cual significa que sus transacciones serán bloqueadas. Para poder reactivar su cuenta o su
                        tarjeta emitida por Cuenca o mediante alianzas comerciales, el Cliente afectado directamente
                        deberá de ponerse en contacto con el Servicio de Atención al Cliente o mediante el ingreso de su
                        Plataforma para activar su tarjeta.
                    </p>

                    <p className={classes.clause}>4. FONDOS</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Cuenca mantendrá los fondos de las Cuentas de los Clientes dentro de una cuenta bancaria
                            concentradora a su nombre ("Cuenta Concentradora") en una Entidad Financiera debidamente
                            autorizada para dichos efectos.
                        </p>
                        <p className={classes.text}>
                            Cuenca mantendrá todos los fondos de los Clientes en la Cuenta Concentradora, la cual se
                            encontrará en todo momento separada de sus fondos corporativos, mismos que serán depositados
                            en una cuenta de depósito distinta. Los fondos de la Cuenta Concentradora no se utilizarán
                            para financiar las operaciones de los negocios de Cuenca o para cualquier otro fin distinto
                            al de llevar a cabo las operaciones solicitadas por los Clientes y no podrán ser otorgados
                            voluntariamente a sus acreedores en caso de concurso mercantil o cualquier otra
                            eventualidad.
                        </p>
                        <p className={classes.text}>
                            El Cliente reconoce que podrá realizar abonos a su Cuenta mediante transferencia electrónica
                            de fondos a través de su número CLABE y/o mediante depósitos en efectivo de conformidad con
                            lo establecido en el numeral 4.1, para lo cual deberá utilizar la Plataforma y seguir las
                            instrucciones proporcionadas por Cuenca.
                        </p>
                        <p className={classes.text}>Los fondos depositados en la Cuenta no generan intereses.</p>
                    </Box>

                    <p className={classes.subclause}>4.1. DEPÓSITOS EN EFECTIVO</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Los Clientes podrán realizar abonos a su Cuenca mediante depósitos en efectivo realizados en
                            los puntos de venta de las cadenas comerciales autorizadas para tales efectos.
                        </p>
                        <p className={classes.text}>
                            La lista de establecimientos autorizados para recibir depósitos en efectivo se dará a
                            conocer al Cliente a través de la Plataforma.
                        </p>
                        <p className={classes.text}>
                            En el momento que se realiza el depósito, el cajero de dicho establecimiento, cobrará la
                            cantidad que se desea depositar más la comisión establecida por la cadena comercial. Estas
                            comisiones no son cobradas a nombre de Cuenca, sino que son propias del establecimiento.
                        </p>
                        <p className={classes.text}>
                            Los montos de dichas comisiones se darán a conocer al Cliente a través de la Plataforma y
                            mediante el <span className={classes.strong}>Anexo A</span> de los presentes Términos y
                            Condiciones.
                        </p>
                        <p className={classes.text}>
                            El Cliente deberá conservar el comprobante de su depósito en efectivo, con el
                            establecimiento correspondiente, lo anterior para efectos comprobatorios en caso de una
                            controversia.
                        </p>
                    </Box>

                    <p className={classes.clause}>5. INSTRUCCIONES DE PAGO Y MANDATO</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Las disposiciones de los fondos sólo podrán ser instruidas por el Cliente a Cuenca a través
                            de la Plataforma.
                        </p>
                    </Box>

                    <p className={classes.subclause}>5.1. DOMICILIACIÓN.</p>
                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            El Cliente podrá solicitar el servicio de domiciliación a Cuenca o a través de un Tercero
                            Autorizado, el cual consiste en la ejecución de transferencias, sean individuales o
                            recurrentes, con cargo a su Cuenta, mediante la autorización expresa del Cliente, mediante
                            del formato de Domiciliación el cual se adjunta a los presentes Términos y Condiciones, como
                            Anexo B.
                        </p>
                    </Box>

                    <p className={classes.subclause}>5.2. MANDATO IRREVOCABLE/COMISIÓN MERCANTIL</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Al llevar a cabo una disposición de fondos, el Cliente otorgará un Mandato Irrevocable en
                            términos de lo dispuesto en los artículos 2546 del Código Civil para el Distrito Federal y
                            sus correlativos de los Códigos Civiles de los Estados de la República Mexicana y del Código
                            Civil Federal y 273 del Código de Comercio a Cuenca para (i) pagar por su cuenta y orden una
                            determinada suma de dinero y/o (ii) cobrar los fondos por su cuenta y orden.
                        </p>
                        <p className={classes.text}>
                            El Mandato Irrevocable otorgado por el Cliente implica una autorización para disponer en su
                            nombre de ciertos fondos de su Cuenta y transferir los fondos a un destinatario determinado,
                            mediante acreditación en una cuenta designada de acuerdo con sus instrucciones. Asimismo, el
                            Mandato Irrevocable implica una autorización del Cliente para cobrar y acreditar en su
                            Cuenta los fondos de acuerdo con sus instrucciones.
                        </p>
                        <p className={classes.text}>
                            Cuenca no utilizará los fondos del Cliente para fines distintos a los instruidos por el
                            Cliente.
                        </p>
                        <p className={classes.text}>
                            El Cliente deberá tener fondos suficientes en su Cuenta a fin de cumplir con las
                            instrucciones de pago; de lo contrario, Cuenca no procesará dicha transacción.
                        </p>
                        <p className={classes.text}>
                            El Cliente es el único responsable de la transacción instruida a través de Cuenca. Cuenca es
                            ajeno a la obligación que le dio origen a dicha instrucción de pago, y, por lo tanto, no
                            está obligado a verificar la cantidad, causa, destinatario o cualquier otra circunstancia
                            relacionada con la instrucción de pago.
                        </p>
                    </Box>

                    <p className={classes.clause}>6. LÍMITES DE TRANSACCIÓN</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            La legislación mexicana establece ciertos límites a la suma de los abonos, realizados
                            derivado de la realización de depósitos en efectivo o recepción de transferencias de fondos,
                            que se pueden realizar a las Cuentas de Fondos de Pago Electrónico. Cuenca tiene 4 niveles
                            distintos disponibles de Cuentas, cada nivel tiene un límite monetario distinto y requiere
                            distinta información personal y/o documentación del Cliente. Completado satisfactoriamente
                            el registro del Cliente, su Cuenta de forma automática será nivel 1. En caso de que el
                            Cliente requiera que se le clasifique en el nivel de Cuenta 2, 2.5 ó 3, deberá proveer la
                            información y/o documentación adicional que le solicite Cuenca.
                        </p>
                        <p className={classes.text}>
                            (i) En las Cuentas Nivel 1 la suma de los abonos realizados en el transcurso de un mes
                            calendario no podrá exceder el equivalente en moneda nacional a $5,000.00. ni de un saldo
                            máximo de $6,500.00.
                        </p>
                        <p className={classes.text}>
                            (ii) En las Cuentas Nivel 2 la suma de los abonos realizados en el transcurso de un mes
                            calendario no podrá exceder el equivalente en moneda nacional a $20,000.00.
                        </p>
                        <p className={classes.text}>
                            (iii) En las Cuentas Nivel 2.5 la suma de los abonos realizados en el transcurso de un mes
                            calendario no podrá exceder el equivalente en moneda nacional a $65,000.00.
                        </p>
                        <p className={classes.text}>
                            (iv) En las Cuentas Nivel 3 la suma de los abonos realizadas mediante transferencias
                            electrónicas de fondos a la cuenta del Cliente no tendrá limite.
                        </p>
                        <p className={classes.text}>
                            Adicionalmente la cuenta nivel 3 podrá recibir abonos mediante depósitos en efectivo, de
                            conformidad con el numeral 4.1 de los presentes, mismos que no podrán exceder al equivalente
                            en moneda nacional a 10,000 UDIS en el transcurso de un mes calendario.
                        </p>
                        <p className={classes.text}>
                            Para realizar el cálculo en UDIS de los límites señalados, Cuenca tomará el valor de dicha
                            unidad de cuenta del último día del mes calendario inmediato anterior al mes de que se
                            trate.
                        </p>
                    </Box>

                    <p className={classes.clause}>7. DISPOSICIÓN DE LOS FONDOS</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            El Cliente podrá disponer de los fondos de su Cuenta girando instrucción a Cuenca para lo
                            siguiente:
                        </p>
                        <p className={classes.text}>
                            (i) Transferencia electrónica a una institución financiera que participe en el Sistema de
                            Pagos Electrónicos Interbancarios (SPEI) del Banco de México.
                        </p>
                        <p className={classes.text}>
                            (ii) Mediante el servicio de transferencias electrónicas de fondos mediante la aplicación
                            "WhatsApp" a través de la cual se podrán enviar los fondos del Cliente, directo a sus
                            contactos registrados en dicha aplicación.
                            <br />
                            <br />
                            (iii) Pago de algún servicio con una empresa con la que Cuenca tenga un arreglo comercial.
                            <br />
                            <br />
                            (iv) En su caso, pagos con tarjeta física o virtual emitida por Cuenca o mediante alianzas
                            comerciales.
                            <br />
                            <br />
                            (v) Domiciliaciones solicitadas por el Cliente.
                        </p>
                        <p className={classes.text}>
                            De igual manera, Cuenca podrá proporcionarle al Cliente o mediante alianzas comerciales una
                            tarjeta como medio de disposición de los fondos de su Cuenta. Dicho medio de disposición se
                            regirá únicamente por los presentes Términos y Condiciones.
                        </p>
                        <p className={classes.text}>
                            El Cliente podrá disponer de los fondos de su Cuenta a través de tarjetas físicas emitidas
                            por Cuenca o alianzas comerciales mediante:
                            <br />
                            <br />
                            (i) El uso de cajeros automáticos o cualquier otro medio de disposición de efectivo, hasta
                            por un monto máximo de 1,500 UDIS por día.
                            <br />
                            <br />
                            (ii) El uso de terminales punto de venta.
                        </p>
                    </Box>

                    <p className={classes.clause}>8. CANCELACIÓN DE LA CUENTA</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            El Cliente podrá cancelar su Cuenta en cualquier momento durante la vigencia del Contrato de
                            Adhesión celebrado con Cuenca y sin condición alguna, enviando un mensaje a:
                            <br />
                            <br />
                            (i) a través de la aplicación de mensajería instantánea denominada "WhatsApp" por medio de
                            la cuenta de empresa oficial verificada de cuenca, registrada con el número telefónico del
                            Cliente.
                            <br />
                            <br />
                            (ii) Mediante el acceso directo a dicho sistema de mensajería disponible en la Plataforma.
                        </p>
                        <p className={classes.text}>
                            Nuestro equipo de Atención a Clientes, al momento de recibir la solicitud de cancelación,
                            con la finalidad de brindarle la mayor seguridad al Cliente y que Cuenca pueda tener
                            completa certeza de la identidad de la persona que contacta, solicitará al Cliente que mande
                            un video de él, con duración no mayor a 15 segundos, sosteniendo en su mano una
                            identificación oficial vigente y en donde mencione: i) fecha en que realiza la solicitud
                            respectiva, ii) nombre completo del Cliente que contacta y iii) la solicitud que realiza
                            hacia Cuenca.
                        </p>
                        <p className={classes.text}>
                            Una vez recibida la solicitud de cancelación de la Cuenta, Cuenca proporcionará al Cliente
                            el número de referencia a través de la misma aplicación de mensajería instantánea mencionada
                            (o por cualquier medio digital) correspondiente a la solicitud de cancelación, mismo que
                            deberá incluir la fecha y la hora en la que se solicitó la referida cancelación de la
                            Cuenta. Esta información deberá ser enviada al Cliente en el momento en que se haya
                            presentado la solicitud o a más tardar al cierre del día hábil bancario en el que se haya
                            presentado dicha solicitud. Adicionalmente, Cuenca deberá conservar evidencia de la
                            información proporcionada por el Cliente para tales efectos.
                        </p>
                        <p className={classes.text}>
                            A partir de la solicitud antes mencionada, se cancelarán: (i) Cualquier nuevo cobro de
                            productos o servicios asociado a la Cuenta; (ii) Los medios de disposición asociados a la
                            Cuenta, incluidos la tarjeta digital, la tarjeta física emitidas por Cuenca o mediante
                            alianzas comerciales y cualquier tipo de transferencia electrónica de fondos; (iii)
                            Cualquier domiciliación asociada a la Cuenta.
                        </p>
                        <p className={classes.text}>
                            El cierre a que se refiere esta Cláusula surtirá efectos al cierre del día hábil en que
                            Cuenca haya recibido la solicitud de cancelación correspondiente. Una vez que surta efectos
                            el cierre referido, Cuenca se abstendrá de realizar cargos en la Cuenca de Fondos de Pago
                            Electrónico de que se trate.
                        </p>
                        <p className={classes.text}>
                            A más tardar al cierre del día hábil de la presentación de la solicitud de cancelación, el
                            saldo remanente en la cuenta será transferido a la cuenta que el Cliente haya especificado
                            para esos efectos. Adicionalmente, Cuenca no podrá condicionar el cierre de la Cuenta una
                            vez que el Cliente haya presentado la referida solicitud de cancelación en los términos
                            antes señalados.
                        </p>
                        <p className={classes.text}>
                            En caso de controversias respecto a la fecha de cierre de la Cuenta, dicha fecha será la
                            establecida por el Cliente a menos de que en dicha controversia exista evidencia escrita por
                            medio de la cual Cuenca pueda justificar una fecha distinta. En caso de controversias sobre
                            la instrucción del Cliente respecto al cierre de su Cuenta de Fondos de Pago Electrónico el
                            Cliente deberá producir la evidencia de la solicitud, el número de referencia, la fecha y
                            hora en que se realizó.
                        </p>
                        <p className={classes.text}>
                            El Cliente no puede evadir ninguna investigación mediante la cancelación de su Cuenta. Si
                            cierra su Cuenta mientras se estaba llevando a cabo una investigación, se podrán retener sus
                            fondos para proteger a Cuenca o a terceros, contra el riesgo de cancelaciones,
                            reclamaciones, comisiones, multas, penalizaciones y otras responsabilidades. El Cliente
                            seguirá siendo responsable de todas las obligaciones relacionadas con su Cuenta, incluso
                            después de la cancelación de esta.
                        </p>
                        <p className={classes.text}>
                            Una vez que la cuenta sea cancelada, los presentes Términos y Condiciones quedarán sin
                            efectos.
                        </p>
                    </Box>
                    <p className={classes.clause}>9. ACLARACIONES</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Una aclaración será el mecanismo mediante el cual se solicita la revisión de cualquier
                            Operación relacionada con el Servicio proporcionado por Cuenca.
                        </p>
                        <p className={classes.text}>
                            Cuenca contará con un equipo encargado de analizar cualquier tipo de aclaraciones, para lo
                            cual el Cliente deberá enviar un mensaje realizando dicha aclaración a través de la
                            aplicación de mensajería instantánea denominada "WhatsApp" por medio de la cuenta de empresa
                            oficial verificada de Cuenca.
                        </p>
                        <p className={classes.text}>
                            El Cliente podrá presentar en cualquier día natural, avisos de reclamaciones por cargos a su
                            Cuenta de Fondos de Pago Electrónico que no reconozca como propios. El Cliente deberá
                            presentar el citado aviso a través del formato establecido como Anexo C de los presentes
                            Términos y Condiciones. El Cliente contará con un plazo de 90 días naturales para presentar
                            el aviso respectivo contado a partir de la fecha en que se realizó el cargo objeto de la
                            reclamación.
                        </p>
                        <p className={classes.text}>
                            Tratándose de servicios de domiciliación con cargo a la Cuenta de Fondos de Pago Electrónico
                            del Cliente, este podrá presentar las objeciones por cargos no reconocidos derivados de la
                            prestación de este servicio. Dichas objeciones deberán presentarse bajo el mismo formato que
                            se establece en el párrafo anterior (Anexo C). El Cliente contará con un plazo de 90 Días
                            Hábiles Bancarios contados a partir de la fecha en que se realizó el cargo respectivo para
                            presentar el aviso de reclamación correspondiente.
                        </p>
                        <p className={classes.text}>
                            Presentados cualquiera de los avisos a que se refieren los dos párrafos que anteceden,
                            Cuenca le proporcionará al Cliente dentro de un plazo máximo de 24 horas, un número de
                            referencia, confirmando la fecha y hora en que se haya presentado el aviso correspondiente.
                        </p>
                        <p className={classes.text}>
                            En relación con los avisos de reclamaciones por cargos a su Cuenta de Fondos de Pago
                            Electrónico que el Cliente no reconozca como propios, Cuenca abonará a más tardar al segundo
                            Día Hábil Bancario, contado a partir del día en que se haya presentado el aviso
                            correspondiente, el monto equivalente a los cargos reclamados, siempre y cuando Cuenca no
                            compruebe al Cliente que los cargos reclamados derivan de Operaciones en las que, para su
                            realización, se requirió al Cliente utilizar, al menos, dos elementos independientes para
                            autenticar las Operaciones como autorizadas por el mismo.
                        </p>
                        <p className={classes.text}>
                            Para los casos de objeciones de cargos no reconocidos derivados de una domiciliación, si la
                            objeción respectiva se presenta dentro de los primeros sesenta Días Hábiles Bancarios
                            contados a partir de la fecha en que se realizó el cargo respectivo, Cuenca deberá abonar a
                            la Cuenta de Fondos de Pago Electrónico del Cliente el monto reclamado a más tardar al Día
                            Hábil Bancario siguiente a la recepción de la objeción correspondiente. Si la objeción se
                            presenta entre el Día Hábil Bancario sesenta y uno a noventa contado a partir de la fecha en
                            que se realizó el cargo respectivo, Cuenca deberá resolver sobre la procedencia de la
                            reclamación en un plazo máximo de veinte Días Hábiles Bancarios y, en caso de que la
                            objeción resulte procedente, abonar el monto reclamado a más tardar al Día Hábil Bancario
                            siguiente a la fecha de resolución. En caso de que la objeción de cargos por Domiciliaciones
                            no resulte procedente, Cuenca pondrá a disposición del Cliente la resolución en la que se
                            expresen los argumentos que sustenten la improcedencia, así como copia de los documentos o
                            evidencia de soporte respectivos, incluyendo la proporcionada por el Tercero Autorizado de
                            que se trate.
                        </p>
                        <p className={classes.text}>
                            Para los casos en que no resulte procedente que Cuenca abone los recursos objeto del cargo
                            no reconocido por el Cliente a su Cuenta de Fondos de Pago Electrónico, Cuenca le pondrá a
                            disposición un dictamen que contendrá la información siguiente: a) evidencia de los
                            elementos de autenticación utilizados, así como la explicación en que la verificación de
                            estos se realizó de acuerdo con los procedimientos aplicables a dichos factores de
                            autenticación; b) hora en la que se realizó la operación; c) nombre del receptor de pagos en
                            donde se originó la operación; y d) en caso de contar con ella, la dirección física en la
                            que se encuentra el dispositivo en el que se realizó la operación o, en su caso, dirección
                            del protocolo de internet (IP, por sus siglas en inglés) a través de la cual dicho
                            dispositivo haya estado conectado a internet.
                        </p>
                        <p className={classes.text}>
                            Para los casos en que sí haya resultado procedente que Cuenca abonara los recursos objeto
                            del cargo no reconocido por el Cliente a su Cuenta de Fondos de Pago Electrónico, Cuenca
                            podrá revertir el abono que hubiere realizado si comprueba al Cliente que los cargos
                            reclamados derivan de Operaciones en las que, para su realización, se le requirió utilizar,
                            al menos, dos elementos independientes para autenticar las Operaciones como autorizadas por
                            el mismo, debiendo poner a su disposición el dictamen a que se refiere el párrafo anterior.
                            Para el supuesto establecido en el presente párrafo, Cuenca contará con un plazo máximo de
                            cuarenta y cinco Días Hábiles Bancarios contados a partir de la fecha en que el Cliente
                            presentó la reclamación correspondiente y de ciento ochenta días naturales en tratándose de
                            Operaciones realizadas en el extranjero.
                        </p>
                    </Box>
                    <p className={classes.clause}>10. HORARIOS DEL SERVICIO Y ATENCION AL CLIENTE.</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            El Servicio ofrecido por Cuenca se encuentra disponible 24 horas al día 7 días a la semana.
                        </p>
                        <p className={classes.text}>
                            El servicio de Atención al Cliente tiene un horario de Lunes a Viernes de 10:00 hrs a 22:00
                            hrs. Y sábados y domingos de 15:00 hrs a 22:00 hrs.
                        </p>

                        <p className={classes.subclause}>10.1. Inactividad en la sesión del Cliente.</p>

                        <p className={classes.text}>
                            Una vez iniciada sesión, el Cliente únicamente contará con un plazo de 5 minutos de
                            inactividad, una vez transcurrido este tiempo la sesión se cerrará automáticamente y deberá
                            ingresar a su Cuenta en la Plataforma mediante el uso de su contraseña. Esta situación será
                            informada al Cliente mediante una notificación en la misma Plataforma.
                        </p>

                        <p className={classes.text}>
                            En caso de caducar una sesión en virtud de la inactividad del usuario dentro de la
                            plataforma, no existirá un límite de tiempo para volver a iniciar sesión el cliente podrá
                            hacerlo de manera inmediata ingresando sus credenciales.
                        </p>

                        <p className={classes.subclause}>10.2. Cobertura Geográfica.</p>

                        <p className={classes.text}>
                            Tu Cuenta de Fondos de Pago Electrónico es un producto ofrecido en todo México.
                        </p>
                    </Box>

                    <p className={classes.clause}>11. ESTADOS DE CUENTA.</p>

                    <p className={classes.subclause}>1. Periodicidad y Consulta de los Estados de Cuenta.</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Cuenca enviará un estado de cuenta de forma mensual al Cliente, a la dirección de correo
                            electrónico indicado en la Plataforma, en donde le permita conocer los cargos y abonos
                            realizados en su Cuenta. En el mismo sentido Cuenca tendrá disponible el estado de cuenta de
                            los tres meses inmediatos anteriores en la sesión del Cliente en la App.
                        </p>
                        <p className={classes.text}>
                            Los Estados de Cuenta deberán contener la información siguiente:
                            <br />
                            <br />
                            a) Saldo inicial y el de la fecha de corte o del final del periodo;
                            <br />
                            <br />
                            b) El promedio de los saldos diarios del periodo;
                            <br />
                            <br />
                            c) La CLABE;
                            <br />
                            <br />
                            d) Beneficios, que en su caso, se hayan generado en el periodo correspondiente, y
                            <br />
                            <br />
                            e) Nombre del establecimiento comercial y lugar donde se utilizó la Tarjeta emitida por
                            Cuenca o mediante alianzas comerciales, solo si fuera el caso en que se hubiere utilizado la
                            misma.
                        </p>
                    </Box>
                    <p className={classes.subclause}>2. Modificación en el envío de Estados de Cuenta.</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Cuenca notificará al Cliente : (i) A través de la Plataforma; (ii) En la dirección de correo
                            electrónico principal registrada por el Cliente; o (iii) A través de la aplicación de
                            mensajería instantánea denominada "WhatsApp" por medio de la cuenta de empresa oficial
                            verificada de cuenca, registrada con el numero telefónico del Cliente, en caso de modificar
                            la forma de envío de los estados de cuenta.
                        </p>
                        <p className={classes.text}>
                            Cuenca solicitará el consentimiento del Cliente para aceptar el nuevo medio de envío de
                            estados de cuenta. En el entendido que, el cliente podrá solicitar a Cuenca, en cualquier
                            momento, el envío de los estados de cuenta a su correo.
                        </p>
                    </Box>

                    <p className={classes.subclause}>3. Consulta de Operaciones.</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Para la consulta de Operaciones realizadas en tu Cuenta Cuenca, deberás ingresar a la
                            Plataforma mediante el uso de tu contraseña y/o otros factores de autenticación, el cual es
                            el único medio por el cual podrás hacerlo. La consulta de Operaciones está disponible en la
                            Plataforma las 24 horas del día, los 7 días de la semana.
                        </p>
                    </Box>
                </Box>

                <Box className={classes.section}>
                    <Box className={classes.subtitleContainer}>
                        <p className={classes.number}>III.</p>
                        <p className={classes.subtitle}>Propiedad Intelectual</p>
                    </Box>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            El Cliente acepta que el Servicio, incluido, pero no limitado al contenido de textos,
                            gráficos, enlaces, botones, logos e imágenes, así como todos los otros derechos de autor y
                            marcas, son de propiedad exclusiva de Cuenca (la "Propiedad intelectual"). El Cliente se
                            compromete a no exponer, usar, copiar o modificar la Propiedad Intelectual en modo alguno.
                            El Cliente está autorizado únicamente para ver y guardar copia de las páginas para su uso
                            personal, no comercial. Además, el Cliente acepta que: (i) No se involucrará ni usará ningún
                            dispositivo automático ni minería de datos, ningún robot, araña, raspador ni ningún otro
                            método similar de recolección o extracción de datos para tener acceso o usar el Servicio;
                            (ii) No modificará, copiará, enmarcará, alquilará, prestará, venderá, distribuirá o creará
                            trabajos derivados del Servicio ni total ni parcialmente, exceptuando la información que el
                            Cliente legalmente suba al Servicio; y (iii) No violará los derechos de autor, marcas, ni
                            ningún otro derecho de propiedad intelectual, ni derechos de publicidad o privacidad de
                            Cuenca, ni de ningún tercero.
                        </p>
                    </Box>
                </Box>

                <Box className={classes.section}>
                    <Box className={classes.subtitleContainer}>
                        <p className={classes.number}>IV.</p>
                        <p className={classes.subtitle}>Condiciones generales de contratación</p>
                    </Box>

                    <p className={classes.clause}>1. CAPACIDAD</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Sólo podrán ser Clientes aquellas personas que tengan capacidad legal para contratar y estén
                            debidamente registrados como Clientes en Cuenca. No podrán registrarse ni utilizar los
                            Servicios: menores de edad, personas que no tengan capacidad para contratar o aquellos
                            Clientes que hayan sido suspendidos o inhabilitados de utilizar los servicios que ofrece
                            Cuenca, temporal o definitivamente.
                        </p>
                    </Box>

                    <p className={classes.clause}>2. UTILIZACIÓN DEL SERVICIO POR CUENTA PROPIA</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            El Cliente manifiesta que en todo momento actúa por cuenta propia y en consecuencia,
                            únicamente tiene permitido utilizar los Servicios en nombre propio y no en representación
                            y/o por cuenta de terceros. Está prohibido que el Cliente revenda, alquile, preste o por
                            algún otro acto jurídico ceda y otorgue los Servicios a terceros.
                        </p>
                    </Box>

                    <p className={classes.clause}>3. CUENCA ES UNA ENTIDAD FINANCIERA</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Se deja expresamente claro que Cuenca no es un banco, no se trata de una Institución de
                            Crédito, y no ofrece ningún tipo de servicio de banca, crédito o servicio cambiario como lo
                            define la Ley de Instituciones de Crédito y sus disposiciones secundarias.
                        </p>
                        <p className={classes.text}>
                            Cuenca actúa bajo al amparo de la Ley para Regular las Instituciones de Tecnología
                            Financiera y la regulación secundaria que de ella emana. Todas las transacciones autorizadas
                            por Cuenca son procesadas a través de la Cuenta Concentradora, que se mantiene en una
                            institución financiera legalmente autorizada y regulada.
                        </p>
                    </Box>

                    <p className={classes.clause}>4. COMISIONES/CONTRAPRESTACIÓN</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Por la utilización de los Servicios, Cuenca únicamente cobrará las comisiones establecidas
                            en el <span className={classes.strong}>Anexo A</span> de los presentes Términos y
                            Condiciones.
                        </p>
                    </Box>

                    <p className={classes.clause}>5. AVISO DE PRIVACIDAD</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Cuenca se compromete a resguardar los datos personales del Cliente atendiendo las
                            obligaciones impuestas por la Ley Federal de Protección de datos Personales en posesión de
                            los Particulares, por lo que ningún usuario podrá realizar un registro dentro de la
                            Plataforma sin previamente conocer y aceptar el Aviso de Privacidad de forma expresa que
                            Cuenca pone a su disposición dentro de la Plataforma en el siguiente vinculo:{' '}
                            <em>
                                <strong>www.cuenca.com</strong>
                            </em>
                            .
                        </p>
                        <p className={classes.text}>
                            El Aviso de Privacidad forma parte integral e inseparable de los Términos y Condiciones.
                        </p>
                    </Box>

                    <p className={classes.clause}>
                        6. LIMITACIÓN DE RESPONSABILIDAD POR EL SERVICIO Y CONTINUIDAD DEL MISMO
                    </p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Cuenca garantizará el acceso y uso continuado o ininterrumpido de sus Servicios. Sin
                            perjuicio de lo anterior, el sistema puede eventualmente no estar disponible debido a
                            dificultades técnicas o fallas de internet o por cualquier otra circunstancia ajena a
                            Cuenca.
                        </p>
                        <p className={classes.text}>
                            En caso de presentar algún tipo de falla o interrupción, Cuenca notificará a sus Clientes de
                            dicha situación, a través de uno o varios de los medios siguientes: (i) A través de la
                            Plataforma; (ii) En la dirección de correo electrónico principal registrada por el Cliente;
                            o (iii) A través de la aplicación de mensajería instantánea denominada "WhatsApp" por medio
                            de la cuenta de empresa oficial verificada de cuenca, registrada con el número telefónico
                            del Cliente.
                        </p>
                        <p className={classes.text}>
                            No obstante lo anterior, en el supuesto que la Plataforma no opere de conformidad con lo
                            dispuesto en los Términos y Condiciones, Cuenca realizará todas las acciones y medidas
                            necesarias de conformidad con sus políticas y procedimientos internos para garantizar la
                            continuidad de los Servicios y Operaciones de los Clientes, en cuyo caso hará del
                            conocimiento de los Clientes las instrucciones y opciones de continuidad del servicio para
                            hacer uso de la Plataforma, a través de uno o varios de los medios siguientes: (i) A través
                            de la Plataforma; (ii) En la dirección de correo electrónico principal registrada por el
                            Cliente; o (iii) A través de la aplicación de mensajería instantánea denominada "WhatsApp"
                            por medio de la cuenta de empresa oficial verificada de Cuenca registrada con el número
                            telefónico del Cliente.
                        </p>
                    </Box>

                    <p className={classes.clause}>7. MODIFICACIONES DE LOS TÉRMINOS Y CONDICIONES</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Cuenca podrá modificar en cualquier momento los Términos y Condiciones. Cuenca notificará
                            los cambios al Cliente enviándole una versión actualizada de dichos términos y condiciones
                            mediante la Plataforma con la fecha de la última modificación, para la correspondiente
                            aceptación de dichas modificaciones.
                        </p>
                        <p className={classes.text}>
                            Todos los términos modificados entrarán en vigor a los 5 días de su publicación.
                        </p>
                    </Box>

                    <p className={classes.clause}>8. TERMINACIÓN DE LA RELACIÓN JURÍDICA.</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Cuenca y el Cliente podrán en cualquier oportunidad de vigencia del Contrato de Adhesión
                            terminarlo sin expresión de causa alguna, lo que implicará el cierre de la Cuenta del
                            Cliente. En cuyo caso, el Cliente deberá proceder de conformidad con en los términos y
                            condiciones previstos en la Sección 8 Cancelación de la Cuenta antes señalada.
                        </p>
                        <p className={classes.text}>
                            Asimismo, Cuenca podrá, en caso de incumplimiento del Cliente a las condiciones de los
                            presentes Términos y Condiciones o a la legislación aplicable en la materia, dar por
                            terminada la prestación del Servicio sin ningún tipo de aviso previo, reservándose el
                            derecho de reclamar los daños y perjuicios que tal incumplimiento le haya causado.
                        </p>
                    </Box>

                    <p className={classes.clause}>9. DOCUMENTACIÓN</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            El Cliente autoriza en forma irrevocable a Cuenca y/o a quien Cuenca designe, a almacenar
                            los comprobantes de las Operaciones, los cuales podrán ser utilizados como documentales,
                            ante las autoridades correspondientes.
                        </p>
                    </Box>

                    <p className={classes.clause}>10. NOTIFICACIONES</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Serán válidas todas las notificaciones realizadas al Cliente por Cuenca en la dirección de
                            correo electrónico principal registrada por éste o a través de mensajes instantáneos al
                            teléfono registrado como dato de contacto enviado a través de la aplicación de mensajería
                            para teléfonos inteligentes que envíe y reciba mensajes mediante internet "WhatsApp".
                        </p>
                    </Box>

                    <p className={classes.clause}>11. DOMICILIOS</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Se fija como domicilio de Cuenca: calle de Varsovia 36, Piso 9, Oficina 910, Colonia Juárez,
                            Cuauhtémoc, C.P. 06600, Ciudad de México.
                        </p>
                        <p className={classes.text}>
                            El domicilio del Cliente será el domicilio registrado ante Cuenca al momento de abrir la
                            Cuenta.
                        </p>
                    </Box>

                    <p className={classes.clause}>12. OTRAS DISPOSICIONES</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Si alguna disposición de estos Términos y Condiciones es inválida o inaplicable en virtud de
                            la legislación aplicable, entonces se modificará e interpretará para cumplir los objetivos
                            de tal disposición en la mayor medida posible en virtud de la legislación aplicable y las
                            disposiciones restantes continuarán en plena vigencia y efecto.
                        </p>
                        <p className={classes.text}>
                            Los encabezados que se incluyen son sólo para su conveniencia, y no serán considerados en la
                            interpretación de este documento.
                        </p>
                        <p className={classes.text}>
                            Estos Términos y Condiciones no limitan los derechos que Cuenca pueda tener bajo secreto
                            comercial, derechos de autor, patentes, u otras leyes.
                        </p>
                        <p className={classes.text}>
                            El fracaso por parte de Cuenca para hacer valer cualquier derecho o disposición, en virtud
                            de los presentes Términos y Condiciones, no constituirá una renuncia a tal derecho o
                            disposición. Ninguna renuncia de cualquier disposición de este documento se considerará una
                            renuncia posterior o continuada a dicha disposición o a cualquier otra.
                        </p>
                        <p className={classes.text}>
                            Los presentes Términos y Condiciones, constituyen el acuerdo íntegro de las Partes con
                            respecto a los asuntos previstos en los mismos y deja sin efecto cualesquiera documento,
                            acuerdo o Términos y Condiciones celebrado por las Partes o aceptado por el Cliente con
                            anterioridad, incluyendo, de manera enunciativa pero no limitativa, cualquier carta de
                            intención o acuerdo en principio.
                        </p>
                    </Box>

                    <p className={classes.clause}>13. CESIÓN</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Estos Términos y Condiciones y todos los derechos y licencias otorgados, no podrán ser
                            transferidos, vendidos o cedidos por el Cliente ni por Cuenca, y cualquier intento de cesión
                            o venta será nulo y no tendrá efecto.
                        </p>
                    </Box>

                    <p className={classes.clause}>14. CONTROVERSIAS</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Si el Cliente tiene una controversia con uno o más Clientes, el Cliente liberará y sacará en
                            paz y a salvo a Cuenca (y sus funcionarios, directores, agentes, empresas de participación
                            conjuntas, empleados y proveedores) de cualesquier reclamación, demanda y daño (directo o
                            indirecto) de cualquier tipo y naturaleza que pudieran derivarse de dichas controversias o
                            tengan cualquier relación con las mismas.
                        </p>
                    </Box>

                    <p className={classes.clause}>15. REGULACIÓN</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Las actividades que realiza Cuenca Tecnología Financiera, S.A. de C.V. Institución de Fondos
                            de Pago Electrónico ("Cuenca") están sujetas a la regulación establecida en la Ley para
                            Regular las Instituciones de Tecnología Financiera y la regulación secundaria que de ella
                            emane.
                        </p>
                    </Box>

                    <p className={classes.clause}>16. RESPONSABILIDAD GUBERNAMENTAL</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Ni el Gobierno Federal ni las entidades de la administración pública paraestatal podrán
                            responsabilizarse o garantizar los recursos de los Clientes que sean utilizados en las
                            Operaciones que celebre Cuenca, así como tampoco asumir alguna responsabilidad por las
                            obligaciones contraídas por Cuenca o por algún Cliente frente a otro, en virtud de las
                            Operaciones que celebren.
                        </p>
                    </Box>

                    <p className={classes.clause}>17. LEGISLACIÓN APLICABLE Y JURISDICCIÓN</p>

                    <Box className={classes.textWrapper}>
                        <p className={classes.text}>
                            Estos Términos y Condiciones serán interpretados de conformidad con la legislación federal
                            de México. Las Partes convienen en someterse a la jurisdicción de los tribunales competentes
                            en la Ciudad de México y renuncian expresamente a cualquier otra jurisdicción que les
                            pudiera corresponder por razón de sus domicilios presentes o futuros, o por cualquier otro
                            motivo.
                        </p>
                        <p className={classes.text}>
                            Leídos por el Cliente los presentes Términos y Condiciones y sabedor de su alcance legal, el
                            Cliente manifiesta expresamente a Cuenca la aceptación de los presentes Términos y
                            Condiciones a través de la Plataforma.
                        </p>
                    </Box>
                </Box>

                <Box className={classes.section}>
                    <Grid container className={classes.subGridClauses}>
                        <Grid item xs={12}>
                            <p className={classes.subtitle}>Anexo A</p>
                            <p className={classes.subtitle}>Comisiones</p>
                        </Grid>
                        <Grid item xs={12}>
                            <p className={classes.clause}></p>
                            <p className={classes.subcomision}>TRANSFERENCIAS</p>
                            <Box className={`${classes.comision} blueComision`}>
                                <Grid item xs={7} className="comisionText">
                                    Cuenca a otros bancos
                                    {normalDots ? '..........................................................' : null}
                                    {mediumDots ? '..........................................' : null}
                                    {smallerDots || inBetweenSize ? '..............................' : null}
                                    {reallySmall ? '...............' : null}
                                    {smallest ? '...................' : null}
                                </Grid>
                                <Grid item xs={5} className={classes.blueContainer}>
                                    <Box
                                        className={classes.rightNumber}
                                        style={{
                                            flexDirection: !blueBreak ? 'column' : 'row',
                                            alignItems: !blueBreak ? 'flex-start' : 'center',
                                        }}
                                    >
                                        <p className="blueSmallText">15</p>
                                        <p style={{ marginBottom: '-2px' }} className="endText">
                                            transferencias mensuales gratis
                                        </p>
                                    </Box>
                                </Grid>
                            </Box>
                            <Box className={classes.comision}>
                                <Box className={classes.comisionFlexStart}>
                                    <Grid item xs={7} className="comisionText">
                                        A partir de la transferencia número 16
                                        {normalDots ? '.................................' : null}
                                        {mediumDots ? '.................' : null}
                                        {smallerDots || inBetweenSize ? '.....' : null}
                                        {reallySmall ? '.' : null}
                                        {smallest ? '..............' : null}
                                    </Grid>
                                    <Grid item xs={5} className={classes.rightNumber}>
                                        <p style={{ marginBottom: '-2px' }} className="endLargeText">
                                            Te informamos que en tu cuenta Cuenca puedes realizar cada mes hasta 15
                                            transferencias electrónicas de fondos gratuitas a una entidad financiera
                                            distinta a Cuenca. Una vez superado este límite mensual, cada una de las
                                            transferencias realizadas hacia entidades distintas a Cuenca tendrán una
                                            comisión de $8.62 + IVA = ($10.00).
                                        </p>
                                    </Grid>
                                </Box>
                            </Box>
                            <p className={classes.subcomision}>OTROS</p>
                            <Box className={classes.comision}>
                                <Grid item xs={7} className="comisionText">
                                    Manejo de cuenta
                                    {normalDots
                                        ? '...................................................................'
                                        : null}
                                    {mediumDots ? '...............................................' : null}
                                    {smallerDots || inBetweenSize ? '...................................' : null}
                                    {reallySmall ? '.....................' : null}
                                    {smallest ? '.............' : null}
                                </Grid>
                                <Grid item xs={5} className={classes.rightNumber}>
                                    <p style={{ marginBottom: '-2px' }} className="endText">
                                        <span className="blueSmallText">$50</span>.00 (43.10 + IVA) mensuales por
                                        concepto de manejo de cuenta.
                                    </p>
                                </Grid>
                            </Box>

                            <p className={classes.space}></p>
                        </Grid>
                    </Grid>
                </Box>

                <Box className={classes.section}>
                    <p className={classes.subtitle}>Anexo B</p>

                    <p className={classes.attachedText}>
                        Formato para solicitar la contratación del servicio de Domiciliación:
                        <br />
                        <br />
                        <span className="signature">
                            <span className="under">&emsp;</span> de{' '}
                            <span className="under">&emsp;&emsp;&emsp;&emsp;&emsp;</span> de 20
                            <span className="under">&emsp;</span>.
                        </span>
                        <br />
                        <br />
                        Cuenca:
                        <br />
                        <br />
                        Solicito y autorizo que se realicen cargos en mi Cuenta, conforme a lo siguiente:
                        <br />
                        <br />
                        Nombre del Tercer Autorizado para instruir cargos en mi <br />
                        Cuenta:{' '}
                        {mobileBreakpoint ? (
                            <span className="under">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                        ) : (
                            ''
                        )}
                        <span className="under">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>.
                        <br />
                        <br />
                        Motivo de la Transferencia de Fondos o Transferencia de Fondos de Pago Electrónico:{' '}
                        <span className="under">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                        <span className="under">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>.
                        <br />
                        <br />
                        En su caso, el número de identificación generado por el Tercero Autorizado (dato no
                        obligatorio): <br />
                        {mobileBreakpoint ? (
                            <span className="under">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                        ) : (
                            ''
                        )}
                        <span className="under">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>.
                        <br />
                        <br />
                        Periodicidad del cargo (semanal, quincenal, mensual, bimestral, semestral, anual, etc.):{' '}
                        <span className="under">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>.<br />
                        Monto máximo fijo del cargo autorizado por periodo de facturación: ${' '}
                        <span className="under">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>.<br />
                        Esta autorización es por plazo indeterminado ( ), o vence el:{' '}
                        <span className="under">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>.
                        <br />
                        <br />
                        Estoy enterado de que en cualquier momento podré solicitar la cancelación de la presente
                        Domiciliación.
                        <br />
                        <br />
                        <span className="signature">
                            <span className="under">
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                            </span>
                            <br />
                            Nombre y firma del cliente
                        </span>
                    </p>
                </Box>

                <Grid container className={classes.subGridClauses} margin-top="113px">
                    <p className={classes.subtitle}>Anexo C</p>
                    <Grid item xs={12}>
                        <p className={classes.subcomision}>FORMATO PARA CARGOS NO RECONOCIDOS</p>
                        <p className={classes.attachedText}>
                            <span className="signature">
                                <span className="under">&emsp;&emsp;&emsp;&emsp;&emsp;</span> de{' '}
                                <span className="under">&emsp;&emsp;&emsp;&emsp;&emsp;</span> de 20
                                <span className="under">&emsp;&emsp;</span>.
                            </span>
                            <br />
                            <br />
                            <span className={classes.subclause}>
                                Cuenca Tecnología Financiera, S.A. de C.V., Institución de Fondos de Pago Electrónico
                            </span>
                            <br />
                            <br />
                            <span className="under">
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                            </span>
                            por mi propio derecho y para efecto de que me sea enviado el Dictamen (respuesta) del
                            presente aviso, señalo como correo electrónico
                            <span className="under">
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                            </span>
                            . Bajo protesta de decir verdad expongo lo siguiente:
                            <br />
                            <br />
                            1.- Solicito a esa institución de fondos de pago electrónico la devolución del monto total
                            de $<span className="under">&emsp;&emsp;&emsp;&emsp;</span> por cargos realizados a partir
                            del día
                            <span className="under">&emsp;&emsp;&emsp;</span> de
                            <span className="under">&emsp;&emsp;&emsp;&emsp;</span>de 20
                            <span className="under">&emsp;</span> , a mi Cuenta de Fondos de Pago Electrónico siguiente
                            (16 dígitos de la Tarjeta o 18 dígitos de la Clave Básica Estandarizada "CLABE"):
                            <span className="under">
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                            </span>
                            .
                            <br />
                            <br />
                            2.- De la Cuenta mencionada, se reportan los siguientes cargos respecto de los cuales
                            solicito una aclaración:
                            <br />
                            <br />
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.tableAllBordersHeaders}>
                                                MOTIVO DE LA RECLAMACIÓN
                                            </TableCell>
                                            <TableCell className={classes.tableAllBordersHeaders}>
                                                FECHA DE TRANSACCIÓN
                                            </TableCell>
                                            <TableCell className={classes.tableAllBordersHeaders}>
                                                NOMBRE DEL NEGOCIO/ RECEPTOR DE PAGOS/CAJERO ATM
                                            </TableCell>
                                            <TableCell className={classes.tableAllBordersHeaders}>IMPORTE</TableCell>
                                            <TableCell className={classes.tableAllBordersHeaders}>
                                                TIPO DE MONEDA
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {[...Array(10)].map(() => (
                                            <TableRow>
                                                <TableCell className={classes.tableAllBorders}></TableCell>
                                                <TableCell className={classes.tableAllBorders}></TableCell>
                                                <TableCell className={classes.tableAllBorders}></TableCell>
                                                <TableCell className={classes.tableAllBorders}></TableCell>
                                                <TableCell className={classes.tableAllBorders}></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <br />
                            <br />
                            3.- De los cargos descritos en el hecho anterior manifiesto lo siguiente:
                            <br />
                            <br />
                            <span className="under">&emsp;&emsp;</span> La transacción antes mencionada nunca fue
                            realizada por mí, tengo la tarjeta en mi poder.
                            <br />
                            <span className="under">&emsp;&emsp;</span> La transacción antes mencionada nunca fue
                            realizada por mí, no tengo la tarjeta en mi poder.
                            <br />
                            <span className="under">&emsp;&emsp;</span> El importe del voucher fue alterado.
                            <br />
                            <span className="under">&emsp;&emsp;</span> Monto Alterado, los cargos que he detallado en
                            la carta no corresponden a los vouchers reales que tengo en mi poder.
                            <br />
                            <span className="under">&emsp;&emsp;</span> Solo realicé una compra en ese
                            negocio/establecimiento, mi tarjeta obra en mi poder y desconozco los cargos descritos en el
                            hecho 2 de la presente aclaración.
                            <br />
                            <span className="under">&emsp;&emsp;</span> Mi tarjeta fue cargada dos veces por la misma
                            transacción.
                            <br />
                            <span className="under">&emsp;&emsp;</span> La nota de crédito (devolución de mercancía) de
                            la transacción arriba señalada, no ha sido acreditada a mi cuenta.
                            <br />
                            <span className="under">&emsp;&emsp;</span> Servicios no prestados o mercancía no recibida,
                            el comercio no prestó los servicios y/o no envío la mercancía y no ha realizado el reembolso
                            al cliente.
                            <br />
                            <span className="under">&emsp;&emsp;</span> Pago por otros medios. Al intentar pagar con mi
                            tarjeta, hubo un problema, por lo que tuve que pagar por otro medio, sin embargo, me han
                            cargado los intentos de pago que realice con mi tarjeta.
                            <br />
                            <span className="under">&emsp;&emsp;</span> Transacción en cajero automático (me otorgó solo
                            una parte del importe total solicitado).
                            <br />
                            <span className="under">&emsp;&emsp;</span> Transacción en cajero automático (no me otorgó
                            cantidad alguna del importe total solicitado).
                            <br />
                            <span className="under">&emsp;&emsp;</span> Cargo Periódico o Recurrente cancelado o no
                            solicitado.
                            <br />
                            <span className="under">&emsp;&emsp;</span> El importe del cargo excede el monto por periodo
                            autorizado.
                            <br />
                            <span className="under">&emsp;&emsp;</span> Cualquier otro supuesto:
                            <span className="under">
                                &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                            </span>
                            <br />
                            <br />
                            <br />
                            Estoy enterado de que si se resuelve en mi contra y esa Institución de Fondos de Pago
                            Electrónico pretende cobrar por la gestión, el cobro de la comisión no podrá exceder de:
                            $0.00. (CERO PESOS 00/100 M.N.)
                            <br />
                            <br />
                            <br />
                            <br />
                            <span className="signature">
                                A T E N T A M E N T E,
                                <br />
                                <br />
                                <br />
                                <br />
                                <span className="underSign">
                                    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                                </span>
                                <br />
                                <span className="signer">
                                    NOMBRE Y FIRMA AUTÓGRAFA DEL CLIENTE TITULAR DE LA CUENTA DE FONDOS DE PAGO
                                    ELECTRÓNICO
                                </span>
                            </span>
                        </p>
                    </Grid>
                </Grid>

                <Box className={classes.section}>
                    <p className={classes.subtitle}>Anexo D</p>
                    <Grid item xs={12}>
                        <p className={classes.subcomision}>FORMATO PARA SOLICITAR LA CANCELACIÓN DEL SERVICIO DE DOMICILIACIÓN</p>
                        <p className={classes.attachedText}>
                            <span className="signature">
                                <span className="under">&emsp;&emsp;&emsp;&emsp;&emsp;</span> de{' '}
                                <span className="under">&emsp;&emsp;&emsp;&emsp;&emsp;</span> de 20
                                <span className="under">&emsp;&emsp;</span>.
                            </span>
                            <br />
                            <br />
                            Cuenca:
                            <br />
                            <br />
                            1. Nombre del Tercero Autorizado para instruir cargos en la Cuenta de Fondos de Pago Electrónico: TARJETAS CUENCA, S.A. DE C.V.
                            <br />
                            <br />
                            2. Motivo de la Transferencia de Fondos o Transferencia de Fondos de Pago Electrónico cuya Domiciliación se solicita cancelar:{' '}
                            <span className="under">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>.
                            <br />
                            <br />
                            En su caso, el número de identificación generado por el Tercero Autorizado (dato no obligatorio):{' '}
                            <span className="under">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>.
                            <br />
                            <br />
                            3. Cualquiera de los datos de identificación de la Cuenta de Fondos de Pago Electrónico donde se efectúa el cargo, siguientes:
                            <br />
                            <br />
                            Número de Tarjeta (16 dígitos):{' '}
                            <span className="under">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>, o
                            <br />
                            <br />
                            Clave Básica Estandarizada ("CLABE") de la Cuenta de Fondos de Pago Electrónico (18 dígitos):{' '}
                            <span className="under">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>.
                            <br />
                            <br />
                            Estoy enterado de que la cancelación surtirá efectos en un plazo no mayor a 3 Días Hábiles Bancarios contado a partir de la fecha de presentación de esta solicitud.
                            <br />
                            <br />
                            <br />
                            <span className="signature">
                                A t e n t a m e n t e,
                                <br />
                                <br />
                                <span className="underSign">
                                    &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                                </span>
                                <br />
                                <span className="signer">
                                    (NOMBRE Y FIRMA AUTÓGRAFA DEL CLIENTE TITULAR DE LA TARJETA)
                                </span>
                            </span>
                        </p>
                    </Grid>
                </Box>

            </Grid>
        </Grid>
    );
}

export default withStyles(styles)(TermsAndConditions);
